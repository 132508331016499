<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/applyfor/banner2.jpg" alt="" />
    </div>
    <div class="container">
      <div class="box">
        <div class="step_box">
          <el-steps :active="2" align-center>
            <el-step title="申请并注册"></el-step>
            <el-step title="上传资料"></el-step>
            <el-step title="提交成功"></el-step>
          </el-steps>
        </div>
        <div class="form_box">
          <el-form
            :model="uploadForm"
            ref="uploadForm"
            label-width="120px"
            label-position="left"
            class="demo-ruleForm"
          >
            <el-row class="id_card_box">
              <el-form-item label="身份证人像面">
                <el-upload
                  multiple
                  :action="uploadImgUrl"
                  list-type="picture-card"
                  ref="idCardFirstUpload"
                  accept="image/jpg,image/jpeg,image/png"
                  :headers="headers"
                  :on-remove="idCardFirstDelete"
                  :on-success="idCardFirstUploadSuccess"
                  :before-upload="beforeIdCardFirstUpload"
                  :file-list="idCardFirst"
                  :limit="1"
                  :show-file-list="true"
                >
                  <i class="el-icon-plus">上传图片</i>
                </el-upload>
              </el-form-item>
              <el-form-item label="身份证国徽面">
                <el-upload
                  multiple
                  ref="idCardSecondUpload"
                  :action="uploadImgUrl"
                  list-type="picture-card"
                  :headers="headers"
                  :on-success="idCardSecondUploadSuccess"
                  :on-remove="idCardSecondDelete"
                  :before-upload="beforeIdCardSecondUpload"
                  accept="image/jpg,image/jpeg,image/png"
                  :file-list="idCardSecond"
                  :show-file-list="true"
                >
                  <i class="el-icon-plus">上传图片</i>
                </el-upload>
              </el-form-item>
            </el-row>

            <!-- 营业执照 -->
            <el-form-item label="营业执照">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-change="handelChange"
                :on-success="handleUploadSuccess"
                :before-upload="handleBeforeUpload"
                ref="imageUpload"
                :on-remove="handleDelete"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="fileList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
            </el-form-item>
            <!-- 其它资质 -->
            <el-form-item label="其它资质" class="other">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="handleOtherUploadSuccess"
                :before-upload="handleOtherBeforeUpload"
                :on-remove="handleOtherDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="otherfileList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="prompt">行业经营许可证等相关资质</div>
            </el-form-item>
            <!-- 企业情况说明 -->
            <el-form-item label="企业情况说明">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="companyDescriptionUpload"
                :before-upload="companyDescriptionBeforeUpload"
                :on-remove="companyDescriptionDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="companyDescriptionList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="template_file">
                <a href="/html/企业情况说明.docx" download rel="noopener noreferrer"
                  >下载空表</a
                >
              </div>
            </el-form-item>
            <!-- 申请表 -->
            <el-form-item label="申请表">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="requestFormImagesUpload"
                :before-upload="requestFormImagesBeforeUpload"
                :on-remove="requestFormImagesDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="requestFormImagesList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="template_file">
                <a href="/html/申请表.docx" download rel="noopener noreferrer"
                  >下载空表</a
                >
              </div>
            </el-form-item>
            <!-- 财务报表 -->
            <el-form-item label="财务报表" v-if="timegap > 90">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="financialStatementsUpload"
                :before-upload="financialStatementsBeforeUpload"
                :on-remove="financialStatementsDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="financialStatementsList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="prompt">可登录山西省电子税务局网站，进入““我要办税-一户式查询-财务报表申报信息查询”页面导出。</div>
            </el-form-item>
            <!-- 纳税申报表 -->
            <el-form-item label="纳税申报表" v-if="timegap > 90">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="taxReturnsUpload"
                :before-upload="taxReturnsBeforeUpload"
                :on-remove="taxReturnsDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="taxReturnsList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="prompt">可登录山西省电子税务局网站，进入“我要办税-一户式查询 -申报信息查询”页面导出。</div>
            </el-form-item>
            <!-- 完税证明 -->
            <el-form-item label="完税证明" v-if="timegap > 90">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="taxPaidProofsUpload"
                :before-upload="taxPaidProofsBeforeUpload"
                :on-remove="taxPaidProofsDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="taxPaidProofsList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="prompt">可登录山西省电子税务局网站，进入“我要办税-证明开具 -开具税收完税证明”页面下载。</div>
            </el-form-item>
            <!-- 社保缴纳情况表 -->
            <el-form-item label="社保缴纳情况表" v-if="timegap > 90">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="socialInsuranceTablesUpload"
                :before-upload="socialInsuranceTablesBeforeUpload"
                :on-remove="socialInsuranceTablesDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="socialInsuranceTablesList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="prompt">登录养老保险网厅，选择“企业职工养老网上申报-报表打印-单位参保证明打印”。</div>
            </el-form-item>
            <!-- 承诺书 -->
            <el-form-item label="承诺书" v-if="timegap < 90">
              <el-upload
                multiple
                :action="uploadImgUrl"
                list-type="picture-card"
                :headers="headers"
                :on-success="commitmentBookUpload"
                :before-upload="commitmentBookBeforeUpload"
                :on-remove="commitmentBookDelete"
                ref="imageUpload"
                accept="image/jpg,image/jpeg,image/png"
                :file-list="commitmentBookList"
                :show-file-list="true"
              >
                <i class="el-icon-plus">上传图片</i>
              </el-upload>
              <div class="template_file">
                <a href="/html/承诺书.docx" download rel="noopener noreferrer"
                  >下载空表</a
                >
              </div>
            </el-form-item>
            <el-form-item class="btn">
              <el-button type="primary" @click="uploadSubmit('form')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addQualification, logoutApi } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      // 成立日期是否大于90天
      timegap: "",
      idCardFirst: [],
      uploadImgUrl: "/prod-api/file/upload",
      headers: {
        Authorization: "",
      },
      isShowGetCode: true,
      dis: false, //是否禁用按钮
      timer: null, //设置计时器,
      count: "", //倒计时
      codeUrl: "",
      captchaEnabled: "",
      uploadForm: {},
      agreement: false,
      fileList: [],
      licenseFile: {},
      idCardFirst: [],
      idCardSecond: [],
      idCardFirstFile: {},
      idCardSecondFile: {},
      socialCreditCodeImages: [],
      // 其他资质
      otherfileList: [],
      otherQualifications: [],
      // 企业情况说明
      companyDescriptionList: [],
      companyDescriptionImages: [],
      // 申请表
      requestFormImagesList: [],
      requestFormImages: [],
      // 财务报表
      financialStatementsList: [],
      financialStatements: [],
      // 纳税申报表
      taxReturnsList: [],
      taxReturns: [],
      // 完税证明
      taxPaidProofsList: [],
      taxPaidProofs: [],
      // 社保缴纳情况表
      socialInsuranceTablesList: [],
      socialInsuranceTables: [],
      // 承诺书
      commitmentBookList: [],
      commitmentBook: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 身份证上传
    idCardFirstDelete(file, fileList) {
      this.idCardFirst = fileList;
    },
    beforeIdCardFirstUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    idCardFirstUploadSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.idCardFirst = [];
        this.idCardFirst = fileList;
        this.idCardFirst.map((item) => {
          this.idCardFirstFile = {
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          };
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    // 反面
    idCardSecondDelete(file, fileList) {
      this.idCardSecond = fileList;
    },
    beforeIdCardSecondUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    idCardSecondUploadSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.idCardSecond = [];
        this.idCardSecond = fileList;
        this.idCardSecond.map((item) => {
          this.idCardSecondFile = {
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          };
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    // 营业执照
    handelChange(file, fileList) {
      this.fileList = fileList;
      // console.log("文件修改执行的函数", file, fileList);
    },
    handleUploadSuccess(response, file, fileList) {
      // console.log("成功", response, file, fileList);
      if (response.code == 200) {
        let fileArr = {};
        this.fileList = [];
        this.fileList = fileList;
        this.socialCreditCodeImages = [];
        this.fileList.map((item) => {
          this.socialCreditCodeImages.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    handleDelete(file, fileList) {
      // console.log("移除文件执行的函数", file, fileList);
      this.filesList = fileList;
    },
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    // 其它资质
    handleOtherUploadSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.otherfileList = [];
        this.otherfileList = fileList;
        this.otherQualifications = [];
        this.otherfileList.map((item) => {
          this.otherQualifications.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    handleOtherDelete(file, fileList) {
      this.otherfileList = fileList;
    },
    handleOtherBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    // 企业情况说明
    companyDescriptionUpload(response, file, fileList) {
      if (response.code == 200) {
        this.companyDescriptionList = [];
        this.companyDescriptionList = fileList;
        this.companyDescriptionImages = [];
        this.companyDescriptionList.map((item) => {
          this.companyDescriptionImages.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    companyDescriptionBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    companyDescriptionDelete(file, fileList) {
      this.companyDescriptionList = fileList;
    },
    // 申请表
    requestFormImagesUpload(response, file, fileList) {
      if (response.code == 200) {
        this.requestFormImagesList = [];
        this.requestFormImagesList = fileList;
        this.requestFormImages = [];
        this.requestFormImagesList.map((item) => {
          this.requestFormImages.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    requestFormImagesBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    requestFormImagesDelete(file, fileList) {
      this.requestFormImagesList = fileList;
    },
    // 财务报表
    financialStatementsUpload(response, file, fileList) {
      if (response.code == 200) {
        this.financialStatementsList = [];
        this.financialStatementsList = fileList;
        this.financialStatements = [];
        this.financialStatementsList.map((item) => {
          this.financialStatements.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    financialStatementsBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    financialStatementsDelete(file, fileList) {
      this.financialStatementsList = fileList;
    },
    // 纳税申报表
    taxReturnsUpload(response, file, fileList) {
      if (response.code == 200) {
        this.taxReturnsList = [];
        this.taxReturnsList = fileList;
        this.taxReturns = [];
        this.taxReturnsList.map((item) => {
          this.taxReturns.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    taxReturnsBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    taxReturnsDelete(file, fileList) {
      this.taxReturnsList = fileList;
    },
    // 完税证明
    taxPaidProofsUpload(response, file, fileList) {
      if (response.code == 200) {
        this.taxPaidProofsList = [];
        this.taxPaidProofsList = fileList;
        this.taxPaidProofs = [];
        this.taxPaidProofsList.map((item) => {
          this.taxPaidProofs.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    taxPaidProofsBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    taxPaidProofsDelete(file, fileList) {
      this.taxPaidProofsList = fileList;
    },
    // 社保缴纳情况表
    socialInsuranceTablesUpload(response, file, fileList) {
      if (response.code == 200) {
        this.socialInsuranceTablesList = [];
        this.socialInsuranceTablesList = fileList;
        this.socialInsuranceTables = [];
        this.socialInsuranceTablesList.map((item) => {
          this.socialInsuranceTables.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    socialInsuranceTablesBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    socialInsuranceTablesDelete(file, fileList) {
      this.socialInsuranceTablesList = fileList;
    },
    // 承诺书
    commitmentBookUpload(response, file, fileList) {
      if (response.code == 200) {
        this.commitmentBookList = [];
        this.commitmentBookList = fileList;
        this.commitmentBook = [];
        this.commitmentBookList.map((item) => {
          this.commitmentBook.push({
            fileName: item.response.data.name,
            localPath: item.response.data.url,
          });
        });
      } else {
        this.$message.error(response.msg);
      }
    },
    commitmentBookBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500MB!");
      }
      return isLt2M;
    },
    commitmentBookDelete(file, fileList) {
      this.commitmentBookList = fileList;
    },
    // 退出
    logout() {
      logoutApi(this.headers.Authorization)
        .then(() => {
          sessionStorage.clear();
        })
        .catch((error) => {});
    },
    /** 提交按钮 */
    uploadSubmit() {
      // 提交完成之后调用退出方法
      this.$refs["uploadForm"].validate((valid) => {
        if (valid) {
          let data = {
            id: this.$route.query.id,
            processCode: this.$route.query.processCode,
            certificateFront: this.idCardFirstFile.localPath,
            certificateBack: this.idCardSecondFile.localPath,
            socialCreditCodeImages: this.socialCreditCodeImages,
            otherQualifications: this.otherQualifications,
            companyDescription: this.companyDescriptionImages,
            requestFormImages: this.requestFormImages,
            financialStatements: this.financialStatements,
            taxReturns: this.taxReturns,
            taxPaidProofs: this.taxPaidProofs,
            socialInsuranceTables: this.socialInsuranceTables,
            commitmentBook: this.commitmentBook,
          };
          addQualification(data).then((res) => {
            if (res.data.code == 200) {
              // this.$message.success("提交成功");
              this.logout();
              // this.$alert("去登录，查看申请进度", {
              //   confirmButtonText: "确定",
              //   callback: (action) => {},
              // });
              this.$router.push({
                path: "/success",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      });
    },
  },
  created() {
    this.timegap = sessionStorage.getItem("buildDate");
    this.uploadImgUrl = "/prod-api/file/upload";
    if (sessionStorage.getItem("token")) {
      this.headers.Authorization = sessionStorage.getItem("token");
    } else {
      this.$message.error("请先申请并注册");
      this.$router.push({
        path: "/applyform",
      });
    }
  },
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 400px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.form_box {
  width: 900px;
  margin: 0 auto;
}

.login-code {
  width: 100%;
  height: 38px;
}

.login-code img {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  vertical-align: middle;
}

.submit1 {
  text-align: center;
}

.btn .el-button {
  width: 160px;
}

.tips a {
  color: #409eff;
}

.template_file a {
  color: #409eff;
}

.step_box {
  margin: 0 auto 30px;
}

.el-upload--picture-card i {
  font-size: 20px;
}

.form_box >>> .el-form-item__label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
  line-height: 40px;
}

.form_box >>> .other .el-form-item__label::before {
  content: "";
}

.id_card_box {
  display: flex;
}

.id_card_box .el-form-item {
  width: 50%;
}

.prompt {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
</style>
